import { ReactNode, useEffect, useRef, useState } from 'react';
import ModalForm, { ModalFormProps } from '../ModalForm';
import { Box, Checkbox, Collapse, TextField } from '@mui/material';
import { GroupLabels } from '../../../entities/Group';
import axios from 'axios';
import { useDerivate } from '../../../requests/derivate';
import { FormType } from '../../../enums/form-type.enum';
import SearchableSelect from '../../SearchableSelect';
import ReactQuill from 'react-quill-v2.0';
import 'react-quill/dist/quill.snow.css';
import { isAdmin } from '../../../utils';
import { useUser } from '../../../contexts/userContext';
interface DerivateModalProps
	extends Omit<ModalFormProps, 'children' | 'formType'> {
	children?: ReactNode;
	clientId: number;
	handleSaveParent: Function;
}
export default function DerivateModal({
	open,
	handleClose,
	title,
	clientId,
	handleSaveParent,
}: DerivateModalProps) {
	const derivate = useDerivate();
	const user = useUser();
	const userIsAdmin = isAdmin(user);
	const formRef = useRef(null);
	const [form, setForm] = useState({ groupId: '' });
	const [groups, setGroups] = useState<GroupLabels[]>([]);
	const [client, setClient] = useState<{ groupName: string | null }>();
	const [errors, setErrors] = useState({ serverError: '' });
	const [editEmail, setEditEmail] = useState(false);

	const [emailValue, setEmailValue] = useState(`
		                        <p>Hola {{name}}!,</p>
                        <br />
                        <p>Queremos contarte que ya derivamos tu consulta para
                          que sea atendida por
                          {{coordinatorName}}. Sabemos lo importante que es
                          recibir el acompañamiento que estás buscando, y en
                          breve recibirás un mensaje por WhatsApp para
                          coordinar. 📲</p>
                        <br />
                        <p>Estamos acá para acompañarte en este camino.</p>
                        <br />
                        <p>Bienvenida!</p>
                        <br />
                        <p>El equipo Mamam 💛</p>`);

	useEffect(() => {
		axios
			.request({ method: 'GET', url: `/clients/${+clientId}/group-name` })
			.then((response) => {
				setClient(response.data);
			})
			.catch((error) => console.error(error));
		axios
			.request({ method: 'GET', url: `/groups/labels` })
			.then((response) => {
				setGroups(response.data.data);
			})
			.catch((error) => console.error(error));

		return setForm({ groupId: '' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		console.log(emailValue);
	}, [emailValue]);

	const handleChange = (e: any): void => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = async () => {
		try {
			await derivate(+clientId, form, emailValue);
			handleSaveParent();
			handleClose();
		} catch (error: any) {
			if (error.code === 'ERR_NETWORK')
				setErrors({ ...errors, serverError: 'true' });
		}
	};

	//modal form ref

	return (
		<ModalForm
			open={open}
			handleClose={handleClose}
			title={title}
			formRef={formRef}
			formType={FormType.DERIVATE}
			hasServerError={!!errors?.serverError}
			disabled={!form?.groupId}
		>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root, .MuiFormControl-root': {
						m: 1,
						marginInline: 3,
						width: '25ch',
					},
				}}
				autoComplete="off"
				ref={formRef}
				onSubmit={(e) => {
					e.preventDefault();
					handleSave();
				}}
			>
				<TextField
					id="currentGroup"
					name="currentGroup"
					disabled
					label="Grupo actual"
					value={client?.groupName || 'Ninguno'}
					variant="standard"
				/>
				<SearchableSelect
					label="Derivar a"
					items={groups}
					defaultValue={''}
					valueProperty="name"
					idProperty="id"
					value={form?.groupId}
					onChange={handleChange}
					name="groupId"
					width="25ch !important"
					required={true}
				/>

				{userIsAdmin && (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Checkbox
							checked={editEmail}
							onChange={() => setEditEmail(!editEmail)}
							title="Editar email"
						/>
						<label>Editar email</label>
					</div>
				)}
				<Collapse in={editEmail} timeout={300}>
					<ReactQuill value={emailValue} onChange={setEmailValue} />
				</Collapse>
			</Box>
		</ModalForm>
	);
}
